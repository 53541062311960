import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subbanner from "../components/Subbanner";
import "./Services.scss";
import { useContext } from "react";
import { ProductContext } from "../components/Context/ProductsContext";
import Popupform from "../components/Popupform";
import { Helmet } from "react-helmet";

function Services() {
  let sttop = () => {
    window.scrollTo(0, 0);
  };

  let url = "https://avon.admin.simboz.com/public/api/get-products";

  let {
    allproducts,
    setAllproducts,
    activetab,
    setActivetab,
    setSingleproduct,
    catimage,
    setCatimage,
    subCategory,
    setSubCategory,
  } = useContext(ProductContext);

  let [loading, setLoading] = useState(false);
  let [sorted, setSorted] = useState([]);

  useEffect(() => {
    sttop();

    axios
      .get(url)
      .then(function (response) {
        setAllproducts(response.data.cats);

        let scat = response.data.cats[activetab];
        // console.log(scat.products, 'sd')

        let b = scat.products.sort((a, b) => {
          return a.sort_by - b.sort_by;
        });

        // console.log(b, 'sorted data')

        setLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // console.log(allproducts);

  let imagearr = [
    "assets/service/fabric.png",
    "assets/service/textile.png",
    "assets/service/petrochemical.png",
    "assets/service/solvents.png",
    "assets/service/basicchemical.png",
    "assets/service/filmsandsheet.png",
  ];

  localStorage.setItem("image", imagearr[activetab]);

  function createMarkup(item) {
    return { __html: item };
  }
  // meta code .

  let [metadata, setMetadata] = useState({
    title: "",
    desc: "",
  });
  let urlL = window.location.href.split("/").at(-1);
  // console.log(activetab, "activetab");
  // console.log(allproducts);
  useEffect(() => {
    if (urlL == "fibers-&-yarns") {
      setMetadata({
        title: "Fiber Supplier in Pakistan | Raw Cotton Supplier in Pakistan",
        desc: "Your reliable source for fibers in Pakistan. We specialize as a trusted raw cotton supplier, ensuring quality materials for diverse industrial applications.",
        seoData: `<h1>Premier Fiber Supplier in Pakistan - Avon Commercial</h1>
        <p>
        Avon Commercial takes pride in being the premier fiber supplier in Pakistan, specializing in providing top-quality raw materials for diverse industries. As a trusted name in the market, our commitment to excellence extends to being a reliable raw cotton supplier. Explore a comprehensive range of fibers, including raw cotton, and elevate your manufacturing processes with Avon Commercial as your dedicated partner. As a leading Cotton Supplier in Pakistan, we pride ourselves on offering a diverse selection of fibers to meet the unique needs of businesses across various sectors, ensuring a one-stop solution for all your raw material requirements.
        </p>

        <h2>Best Yarn Suppliers in Pakistan: Avon Commercial</h2>
        <p>Avon Commercial is a top yarn supplier in Pakistan, supplying a wide variety of high-quality yarns to fulfill the needs of various businesses across the country. With a strong commitment to excellence, Avon Commercial is your reliable source for high-quality yarns that improve your manufacturing processes. As the market's top supplier, we take pride in offering a diverse range of yarns, including polyester, cotton, and blended varieties, ensuring that businesses have access to the best fibers for their production needs. With Avon Commercial as your dedicated yarn supplier, you are guaranteed unmatched quality, reliability, and service, making us the top choice for businesses searching excellence in yarn procurement.</p>

        <h2>Unrivaled Quality in Raw Cotton Supply</h2>
        <p>
        At Avon Commercial, we understand the critical role raw materials play in the production cycle. As a distinguished raw cotton supplier in Pakistan, we offer unrivaled quality in our products. Our commitment to sourcing the finest cotton ensures that businesses receive fibers meeting the highest standards of softness, durability, and versatility. Choose Avon Commercial for raw cotton that forms the foundation of exceptional products in various industries. Our extensive experience as a Fiber Supplier allows us to provide not only top-notch raw cotton but also expert insights and support to optimize your manufacturing processes for efficiency and quality.
        </p>
        <h2>Avon Commercial - Your Trusted Cotton Supplier in Pakistan</h2>
        <p>
        Avon Commercial stands at the forefront as your trusted fiber supplier, delivering excellence in every strand of raw cotton. With a focus on meeting the dynamic demands of the Pakistani market, we cater to businesses of all scales – from small-scale entrepreneurs to large-scale industries. Choose Avon Commercial as your cotton supplier for a reliable partnership that ensures the seamless availability of high-quality fibers, contributing to the success of your manufacturing endeavors. As one of the leading Cotton Suppliers in Pakistan, we are dedicated to fostering long-term relationships with our clients by consistently providing superior quality fibers and unmatched customer service.
        </p>`,
      });
      setActivetab(0);
    } else if (urlL === "textiles") {
      setMetadata({
        title: "Top Fabrics Supplier in Pakistan | Textile Fabrics Supplier",
        desc: "Your top fabrics supplier in Pakistan offering a wide range of textile fabrics. Trusted fabric supplier with a commitment to quality and innovation.",
        seoData: ` <h1>Avon Commercial - Your Top Fabrics Supplier in Pakistan</h1>
        <p>
        Avon Commercial proudly holds the title of the top fabrics supplier in Pakistan, committed to providing exceptional textile fabrics to meet the diverse needs of the industry. As a reliable and innovative partner, we understand the importance of superior materials in textile manufacturing. Explore our extensive range of fabrics and experience the difference when you choose Avon Commercial as your dedicated textile fabrics supplier. Our comprehensive selection of fabrics caters to a wide array of applications, ensuring that we stand as your go-to choice for all your fabric needs. Trust Avon Commercial to elevate your textile creations with quality fabrics that set the standard in the industry.
        </p>
        <h2>Unmatched Expertise as Textile Fabrics Supplier</h2>
        <p>
        With a wealth of experience, Avon Commercial stands out as a distinguished textile fabrics supplier in Pakistan. Our commitment to excellence is reflected in the quality and versatility of our fabric offerings. As textile agents in Pakistan, we go beyond traditional supply, ensuring that our fabrics meet the highest industry standards, contributing to the success and innovation of textile manufacturers across the country. Our role as Textile Agents in Pakistan enables us to provide not only top-tier fabrics but also valuable insights and solutions to enhance your textile manufacturing processes.
        </p>
        <h2>Your Trusted Fabric Supplier in Pakistan - Avon Commercial</h2>
        <p>
        Avon Commercial is not just a fabric supplier; we are your trusted partner in textile endeavors. Whether you are a small-scale business or a large-scale industry, our commitment to delivering top-tier fabrics remains unwavering. Choose Avon Commercial as your fabric supplier in Pakistan, and benefit from our dedication to quality, innovation, and customer satisfaction in every woven thread. As a Fabric Supplier in Pakistan, we take pride in fostering enduring relationships with our clients, offering not just fabrics but a collaborative partnership that propels the success of your textile ventures.
        </p>
       `,
      });
      setActivetab(1);
    } else if (urlL === "petrochemicals") {
      setMetadata({
        title: "Petrochemicals Supplier in Pakistan | Resin Supplier",
        desc: "Your reliable Petrochemicals Supplier in Pakistan. Choose excellence with our trusted Resin and Polymer solutions for diverse industrial applications.",
        seoData: `   <h1>Polymer Supplier in Pakistan - Unraveling Innovation at Avon Commercial</h1>
        <p>
        As a leading petrochemical supplier in Pakistan, Avon Commercial takes pride in its role as a premier polymer supplier. Our commitment to innovation is showcased through an extensive range of high-quality polymers tailored to meet the dynamic needs of various industries. Whether you're in plastics, textiles, or manufacturing, Avon Commercial stands as a reliable partner, providing polymers that embody excellence, versatility, and adherence to the highest industry standards. Choose us as your polymer supplier to infuse your products with the cutting-edge materials needed for success in a competitive market. Avon Commercial, your trusted source for innovative polymers, is committed to propelling your business forward with materials that not only meet but exceed industry expectations.
        </p>
      
        <h2>Resin Supplier Delivering Excellence - Avon Commercial's Commitment</h2>
        <p>
        Avon Commercial, with its distinguished position as a resin supplier in Pakistan, remains unwavering in its commitment to excellence. Our extensive range of resins caters to the multifaceted needs of industries such as construction, adhesives, and coatings. As a trusted partner, we ensure that our resins are not only of the highest quality but also align with the evolving demands of the market. When you choose Avon Commercial as your resin supplier, you're choosing a reliable source that prioritizes durability, performance, and the success of your manufacturing processes. Count on Avon Commercial to provide not just resins but a partnership that enhances the resilience and longevity of your products.
        </p>
        <h2>Petrochemical Supplier of Choice - Avon Commercial's Versatility and Quality</h2>
        <p>
        Avon Commercial stands at the forefront as the petrochemical supplier of choice in Pakistan. Our commitment to delivering top-quality raw materials extends beyond polymers and resins. With a diverse range of petrochemical products, we cater to the multifaceted needs of the Pakistani market. From small-scale entrepreneurs to large-scale industries, our products are tailored to meet varying production scales, ensuring that businesses have a steadfast source for the versatile and durable materials necessary for success in an ever-evolving industrial landscape. Choose Avon Commercial as your trusted partner on the journey toward sustainable growth. As a Petrochemical Supplier, we not only provide materials but also contribute to the strategic growth and resilience of your business with our versatile and quality products.
        </p>
        <h2>Resin Solutions Tailored to Your Needs</h2>
        <p>Avon Commercial stands as the premier resin supplier in Pakistan, dedicated to delivering exceptional products and service. With a comprehensive range of resins tailored to diverse industrial applications, we ensure unmatched quality and performance. As your trusted resin supplier in Pakistan, we prioritize reliability and innovation to meet the evolving needs of the market. Partnering with Avon Commercial means accessing a wealth of expertise and resources dedicated to enhancing the durability and functionality of your products. Choose Avon Commercial as your resin supplier in Pakistan, and experience excellence in every aspect of your manufacturing processes.</p>
        `,
      });
      setActivetab(2);
    } else if (urlL === "solvents") {
      setMetadata({
        title: "Solvents Supplier in Pakistan | Industrial Solvents Supplier",
        desc: "Your trusted Solvents Supplier in Pakistan, specializing in high-quality Industrial Solvents. Explore our reliable solutions for your diverse industrial needs.",
        seoData: `    <h1>Avon Commercial - Your Reliable Solvents Supplier in Pakistan</h1>
        <p>
        Avon Commercial takes pride in being a reliable solvents supplier in Pakistan, specializing in industrial solvents for various applications. As a trusted name in the market, we are committed to delivering top-notch solvents to cater to diverse industrial needs. Choose Avon Commercial as your dedicated solvent supplier, ensuring efficiency, safety, and adherence to rigorous industry standards. Our extensive selection of solvents caters to a wide range of industries, making us the go-to choice for businesses seeking a comprehensive and reliable source for their solvent requirements.
        </p>
        <h2>Unparalleled Quality in Industrial Solvents Supply </h2>
        <p>
        Discover unparalleled quality with Avon Commercial's industrial solvents. As a leading solvent supplier in Pakistan, we understand the pivotal role these components play in numerous industries, including manufacturing, electronics, and automotive. Our solvents are engineered to ensure optimal performance, efficiency, and safety, meeting the stringent requirements of modern industrial processes. When you choose Avon Commercial, you're not just selecting solvents; you're investing in a partnership that prioritizes quality and precision in every industrial application. 
        </p>
        <h2>Avon Commercial - The Foremost Choice for Solvent Solutions</h2>
        <p>Avon Commercial stands as the foremost solvent supplier in Pakistan, offering a comprehensive range of industrial and organic solvents. Our commitment to innovation and excellence is resolute as we continue to refine our offerings. For businesses seeking superior solvent solutions tailored to their needs, Avon Commercial is the partner you can rely on for optimal performance and adherence to sustainable practices. As your foremost Solvent Supplier in Pakistan, we are dedicated to providing not only high-quality solvents but also innovative solutions that contribute to the success and efficiency of your industrial processes. Trust Avon Commercial to be your strategic partner in solvent supply, ensuring your business stays at the forefront of industry standards.</p>`,
      });
      setActivetab(3);
    } else if (urlL === "basic-chemicals") {
      setMetadata({
        title: "Industrial Chemicals Supplier in Pakistan | Organic Chemical",
        desc: "Your go-to Chemicals Supplier in Pakistan. Explore our range of quality Industrial Chemicals and Organic Chemicals for reliable solutions and superior performance.",
        seoData: ` <div>
        <h1>Avon Commercial - Your Trusted Industrial Chemicals Supplier in Pakistan</h1>
        <p>
        Avon Commercial stands as a trusted industrial chemicals supplier in Pakistan, offering a comprehensive range of high-quality products to meet the diverse needs of various industries. As a dedicated partner, we are committed to providing top-tier industrial and organic chemicals, contributing to the success and growth of businesses across the spectrum. With our extensive portfolio of chemicals, we strive to be more than just a supplier; we aim to be your strategic ally in achieving excellence in your industrial processes.
        </p>
        <h2>Unrivaled Excellence in Chemical Supply</h2>
        <p>
        Experience unrivaled excellence with Avon Commercial's industrial chemicals. As a prominent Industrial chemicals supplier in Pakistan, we understand the importance of these compounds in various manufacturing processes. Our commitment to quality and efficiency positions us as a cornerstone in the chemical supply chain, supporting the growth and success of industries through top-tier chemical solutions. When you choose Avon Commercial, you are not just obtaining chemicals; you are securing a reliable source that prioritizes excellence and precision in every chemical supply.
        </p>
        <h2>Avon Commercial - Paving the Way for Sustainable Chemical Solutions</h2>
        <p>
        In a dynamic industrial landscape, the role of a trusted chemical supplier is indispensable. Avon Commercial's dedication to innovation and environmental responsibility makes us a vital partner in Pakistan's chemical supply chain. Choose us for a reliable source of industrial and organic chemicals, contributing to a greener future while fulfilling the chemical needs of industries across the nation. As your dedicated Chemical Supplier in Pakistan, we are committed to not only meeting but exceeding your expectations, offering sustainable chemical solutions that align with evolving industry standards and environmental consciousness. Trust Avon Commercial to lead the way towards a more sustainable and efficient chemical future for your business.
        </p>
      </div>`,
      });
      setActivetab(4);
    } else if (urlL === "films-&-sheets") {
      setMetadata({
        title: "Plastic Film & Sheet Supplier in Pakistan | Packaging Sheets",
        desc: "Avon Commercial is global sourcing solutions. We are the leading plastic sheets supplier, offering plastic film and packaging sheets in Pakistan.",
        seoData: `<h1>Plastic Sheets Supplier</h1>
        <p>
          Avon Commercial takes pride in being a leading plastic sheets supplier,
          offering a diverse and high-quality range of plastic sheet solutions to
          meet a broad spectrum of industrial and commercial needs. Plastic sheets
          are integral components used across industries for their versatility,
          durability, and cost-effectiveness, making Avon Commercial a dependable
          partner for sourcing these essential materials.
        </p>
        <p>
          Avon Commercial's dedication to providing top-tier packaging sheets in
          Pakistan and a focus on sustainability positions us as a trusted partner
          for businesses seeking reliable packaging and industrial materials. With
          our vast range of offerings and commitment to quality, we empower
          industries across Pakistan to excel in their operations and meet the
          challenges of a dynamic market.
        </p>
        <h2>Packaging Sheets in Pakistan</h2>
        <p>
          In the realm of packaging, Avon Commercial stands out as a reliable
          provider of packaging sheets in Pakistan. Packaging sheets, crafted from
          various types of plastic, are essential for protecting goods during
          transit, maintaining product integrity, and enhancing shelf appeal. As the
          demand for efficient and sustainable packaging solutions grows, Avon
          Commercial offers a range of packaging sheets catering to the unique
          requirements of businesses nationwide.
        </p>
        <p>
          In the realm of packaging, Avon Commercial stands out as a reliable
          provider of packaging sheets in Pakistan. Packaging sheets, crafted from
          various types of plastic, are essential for protecting goods during
          transit, maintaining product integrity, and enhancing shelf appeal. As the
          demand for efficient and sustainable packaging solutions grows, Avon
          Commercial offers a range of packaging sheets catering to the unique
          requirements of businesses nationwide.
        </p>`,
      });
      setActivetab(5);
    } else if (urlL === "ferrous-scrap") {
      setMetadata({
        title: "Ferrous Scrap Metal Supplier In Pakistan | Ferrous Steel Scrap",
        desc: "Reliable Ferrous Scrap Metal Supplier in Pakistan. High-quality ferrous steel scrap for industrial needs. Competitive pricing and timely delivery.",
        seoData: `<h1>Your Trusted Source for Ferrous Steel Scrap Excellence</h1>
      <p>
        Avon Commercial stands out as the leading Ferrous Scrap metal supplier
        in Pakistan, dedicated to providing high-quality ferrous steel scrap for
        a multitude of industrial applications. Our ferrous steel scrap is
        meticulously sourced and processed to meet stringent industry standards,
        ensuring that you receive material that is both reliable and effective.
        Whether you are involved in manufacturing, construction, or recycling,
        our ferrous steel scrap offers the durability and performance you need.
        Our reputation for excellence is built on consistent product quality and
        a commitment to customer satisfaction, making us the go-to supplier for
        all your ferrous scrap needs.
      </p>
      <h2>High-Quality Ferrous Scrap for Industrial Applications</h2>
      <p>
        As a premier ferrous steel scrap supplier in Pakistan, Avon Commercial
        delivers top-tier scrap metal that supports various industrial
        processes. Our ferrous steel scrap is ideal for use in a range of
        sectors, including automotive, construction, and recycling industries.
        We ensure that our scrap metal meets rigorous quality control measures
        to provide you with a product that enhances operational efficiency and
        durability. With a focus on quality, our ferrous scrap helps you
        maintain high standards in your projects and operations, providing the
        reliability required for your industrial needs.
      </p>
      <h2>Dependable Ferrous Scrap Supplier with Competitive Advantages</h2>
      <p>
        Choosing Avon Commercial means partnering with a ferrous scrap supplier
        known for its dependability and industry expertise. Our commitment to
        reliable service is reflected in our prompt and efficient delivery,
        ensuring that you receive your ferrous scrap on schedule. We understand
        the importance of timely procurement and strive to exceed your
        expectations with our responsive service and high-quality products. By
        choosing us, you benefit from our extensive industry knowledge and
        streamlined logistics, which work together to support your business’s
        operational efficiency and project timelines.
      </p>
      <h2>Affordable Pricing and Efficient Delivery Solutions</h2>
      <p>
        At Avon Commercial, we are committed to offering competitive pricing for
        our ferrous steel scrap while maintaining the highest standards of
        quality. We understand that cost-effectiveness is a key consideration in
        your procurement process, which is why we provide excellent value for
        money without compromising on quality. Our efficient delivery solutions
        are designed to meet your needs promptly, ensuring that your ferrous
        scrap arrives on time and supports your operational requirements. With
        our focus on affordability and timely delivery, we offer a comprehensive
        solution for all your ferrous scrap needs, making us your ideal partner
        for high-quality scrap metal.
      </p>
      <h2>Summary of Avon Commercial’s Ferrous Scrap Solutions</h2>
      <p>
        Avon Commercial excels as a ferrous scrap metal supplier in Pakistan,
        offering premium ferrous steel scrap that meets the highest industrial
        standards. Our commitment to quality, reliability, and competitive
        pricing makes us the preferred choice for businesses across various
        sectors. By providing top-notch scrap metal and efficient delivery
        solutions, we ensure that you have the resources you need to succeed in
        your projects and operations. Partner with Avon Commercial for all your
        ferrous scrap requirements and experience exceptional service and
        product excellence that supports your industrial goals effectively.
      </p>`,
      });
      setActivetab(6);
      setSubCategory(0);
    } else if (urlL === "non-ferrous-scrap") {
      setMetadata({
        title: "Non-Ferrous Scrap Metal Supplier in Pakistan | Stainless Steel Scraps",
        desc: "Trusted Non-Ferrous Scrap Metal Supplier in Pakistan. Premium stainless-steel scraps available. Quality materials, competitive rates, and prompt delivery.",
        seoData: ` <h1>Your Reliable Partner for High-Quality Stainless-Steel Scrap</h1>
      <p>
        Avon Commercial is a trusted non-ferrous scrap metal supplier in
        Pakistan, committed to delivering premium stainless-steel scraps for
        diverse industrial applications. Our stainless-steel scrap is sourced
        with precision and processed to meet the highest quality standards,
        ensuring that our customers receive top-grade materials that enhance
        their operational efficiency. Whether you are in manufacturing,
        construction, or recycling, our stainless-steel scrap offers the
        strength and reliability needed for your projects. Avon Commercial’s
        dedication to quality and customer satisfaction positions us as the
        preferred choice for non-ferrous scrap metal in Pakistan.
      </p>
      <h2>Premium Non-Ferrous Scrap for Industrial Applications</h2>
      <p>
        As a premier stainless steel scrap supplier, Avon Commercial specializes
        in providing non-ferrous scrap metal that meets the demands of various
        industries. Our stainless-steel scrap is ideal for a wide range of
        applications, from automotive and aerospace to construction and
        electronics. We take pride in offering scrap metal that not only meets
        but exceeds industry expectations, providing durability, corrosion
        resistance, and high performance. Our focus on quality ensures that your
        industrial processes are supported by reliable materials, making us the
        go-to supplier for your non-ferrous scrap needs.
      </p>
      <h2>Trusted Non-Ferrous Scrap Supplier with Unmatched Reliability</h2>
      <p>
        When you choose Avon Commercial, you are partnering with a non-ferrous
        steel scrap supplier known for its consistency and reliability. We
        understand the critical role that high-quality scrap metal plays in your
        operations, and we are committed to delivering materials that you can
        count on. Our extensive experience in the industry allows us to offer
        not only superior products but also unparalleled customer service. We
        ensure timely delivery of your scrap metal, helping you maintain your
        project schedules and avoid costly delays. Avon Commercial is dedicated
        to being a reliable partner in your supply chain, providing the support
        and resources you need for success.
      </p>
      <h2>Competitive Pricing and Swift Delivery Solutions</h2>
      <p>
        At Avon Commercial, we offer stainless steel scrap at competitive prices
        without compromising on quality. We understand that cost-efficiency is
        vital to your business, and our pricing structure is designed to provide
        you with the best value for your investment. In addition to our fair
        pricing, we pride ourselves on our prompt and efficient delivery
        services, ensuring that your materials arrive on time and in excellent
        condition. By choosing Avon Commercial, you benefit from a seamless
        supply chain experience, with high-quality non-ferrous scrap delivered
        to your doorstep, ready to support your industrial requirements.
      </p>
      <h2>Overview of Avon Commercial’s Non-Ferrous Scrap Offerings</h2>
      <p>
        Avon Commercial is a leading non-ferrous scrap metal supplier in
        Pakistan, offering premium stainless-steel scraps that meet the
        stringent demands of various industries. Our commitment to quality,
        reliability, and competitive pricing ensures that you receive the best
        materials for your projects. With a focus on delivering top-notch scrap
        metal and exceptional customer service, we are your ideal partner for
        all non-ferrous scrap needs. Trust Avon Commercial to provide the
        high-quality scrap that supports your operations, helping you achieve
        your industrial objectives with confidence and ease.
      </p>`,
      });
      setActivetab(6);
      setSubCategory(1);
    } else if (urlL === "re-rollable-scrap") {
      setMetadata({
        title: "Re-Rollable Scrap Supplier in Pakistan | Avon Commercial",
        desc: "Leading Re-Rollable Scrap Supplier in Pakistan. Avon Commercial offers top-quality re-rollable scrap with reliable service and competitive pricing.",
        seoData: ` <h1>Your Go-To Source for High-Quality Re-Rollable Steel Scrap</h1>
      <p>
        Avon Commercial stands as a leading re-rollable scrap supplier in
        Pakistan, dedicated to providing top-quality re-rollable steel scrap to
        meet the needs of various industries. Our re-rollable scrap is carefully
        sourced and processed to ensure it meets the highest standards of
        quality, making it ideal for a range of industrial applications. Whether
        you're in the business of manufacturing, construction, or metal
        fabrication, our re-rollable scrap offers the strength and reliability
        you need. Avon Commercial's commitment to excellence and customer
        satisfaction makes us the preferred choice for businesses seeking
        dependable re-rollable steel scrap.
      </p>
      <h2>Premium Re-Rollable Steel Scrap for Industrial Applications</h2>
      <p>
        As a premier re-rollable steel scrap supplier, Avon Commercial
        specializes in delivering materials that are essential for industrial
        success. Our re-rollable scrap is perfect for producing high-quality
        steel products, offering durability and consistency in every batch. We
        understand the importance of reliable materials in your operations, and
        we go the extra mile to ensure our scrap meets your exacting
        requirements. By choosing Avon Commercial, you're not just getting scrap
        metal; you're securing a resource that supports the efficiency and
        quality of your industrial processes.
      </p>
      <h2>Reliable and Consistent Re-Rollable Scrap Supply</h2>
      <p>
        At Avon Commercial, we pride ourselves on being a re-rollable scrap
        supplier that businesses can trust for consistent quality and supply. We
        recognize that your production timelines are crucial, and our reliable
        supply chain ensures that you get the materials you need when you need
        them. Our re-rollable scrap is available in bulk quantities, providing
        you with the flexibility to scale your operations without worrying about
        material shortages. Avon Commercial's focus on customer satisfaction
        means you can count on us to deliver what you need, when you need it,
        keeping your projects on track and within budget.
      </p>
      <h2>Competitive Pricing and Superior Service</h2>
      <p>
        Avon Commercial offers re-rollable steel scrap at competitive prices,
        making it easier for you to manage your budget without sacrificing
        quality. We understand the importance of cost-effectiveness in today's
        market, and our pricing strategy is designed to offer you the best
        value. In addition to competitive pricing, we are committed to providing
        superior customer service, ensuring that your experience with us is
        seamless and satisfying. From your initial inquiry to the final
        delivery, Avon Commercial is here to support you with expert advice,
        timely responses, and dependable service.
      </p>
      <h2>Overview of Avon Commercial’s Re-Rollable Scrap Solutions</h2>
      <p>
        Avon Commercial is a trusted re-rollable scrap supplier in Pakistan,
        offering high-quality re-rollable steel scrap that meets the needs of
        various industrial sectors. With a focus on quality, reliability, and
        competitive pricing, we provide the materials that help you achieve your
        business goals. Our dedication to excellent customer service and timely
        delivery ensures that your operations run smoothly, making Avon
        Commercial the ideal partner for all your re-rollable scrap needs. Trust
        us to deliver the quality and service you deserve, every time.
      </p>`,
      });
      setActivetab(6);
      setSubCategory(2);
    } else if (urlL === "isri-specifications") {
      setMetadata({
        title: "ISRI Standard Steel Scrap Supplier In Pakistan | ISRI Steel Scrap",
        desc: "Top ISRI Standard Steel Scrap Supplier in Pakistan. High-quality ISRI steel scrap available with competitive prices and prompt delivery.",
        seoData: ` <h1>Premium ISRI Steel Scrap for Your Industrial Needs</h1>
      <p>
        When it comes to sourcing high-quality steel scrap, Avon Commercial is
        your go-to ISRI standard steel scrap supplier in Pakistan. Our steel
        scrap adheres strictly to the Institute of Scrap Recycling Industries
        (ISRI) standards, ensuring that you receive only the best material for
        your industrial needs. Whether you're in the construction,
        manufacturing, or metal fabrication industry, our ISRI steel scrap
        offers unmatched quality, durability, and reliability. With Avon
        Commercial, you're choosing a partner committed to providing materials
        that meet the rigorous demands of your projects.
      </p>
      <h2>Comprehensive Range of ISRI Steel Scrap Solutions</h2>
      <p>
        At Avon Commercial, we offer a comprehensive range of ISRI steel scrap
        options to cater to diverse industrial applications. Our product lineup
        includes a variety of steel scrap types, all processed to meet ISRI
        standards, guaranteeing consistency and quality in every shipment. From
        heavy melting steel (HMS) to shredded steel scrap, our inventory is
        designed to meet the specific needs of your industry. As a leading ISRI
        Steel Scrap Supplier, we understand the critical role that quality
        materials play in your operations, and we are here to ensure that you
        have access to the best scrap for your projects.
      </p>
      <h2>Reliable Supply and Competitive Pricing</h2>
      <p>
        As an established ISRI steel scrap supplier, Avon Commercial takes pride
        in offering a reliable supply chain that you can count on. We understand
        that timely delivery and consistent availability are crucial to your
        business success, which is why we maintain a robust logistics network to
        ensure prompt delivery of your orders. In addition to our reliable
        supply, we also offer competitive pricing, helping you manage your costs
        effectively without compromising on the quality of your materials. Avon
        Commercial is committed to being a dependable partner for all your ISRI
        steel scrap needs.
      </p>
      <h2>Excellence in Service and Customer Satisfaction</h2>
      <p>
        At Avon Commercial, we believe that excellence in product quality should
        be matched by excellence in service. As your trusted ISRI steel scrap
        supplier, we are dedicated to providing exceptional customer support at
        every stage of your order. From helping you select the right type of
        ISRI steel scrap to ensuring timely delivery, our team is here to make
        your experience with us seamless and satisfactory. We understand that
        your projects are time-sensitive, and we are committed to helping you
        achieve your goals with the highest level of professionalism and care.
      </p>
      <h2>Overview of Avon Commercial’s ISRI Steel Scrap Offerings</h2>
      <p>
        Avon Commercial is a leading ISRI standard steel scrap supplier in
        Pakistan, offering a wide range of high-quality ISRI steel scrap that
        meets the stringent standards of the industry. Our commitment to
        quality, reliability, and customer satisfaction makes us the preferred
        choice for businesses seeking top-grade steel scrap. With competitive
        pricing and a strong focus on timely delivery, Avon Commercial is your
        trusted partner for all your ISRI steel scrap needs. Choose us for
        reliable, high-quality materials that support the success of your
        industrial projects.
      </p>`,
      });
      setActivetab(6);
      setSubCategory(3);
    } else {
      setMetadata({
        title: "Scrap Metal Supplier | Steel Scrap Metal Prices | Metal Scrap Supplier",
        desc: "Reliable scrap metal supplier offering competitive steel scrap prices. Quality metal scrap for industrial needs. Contact us for top-grade metal recycling. ",
        seoData: `<h1>Your Reliable Scrap Metal Supplier for Quality Materials</h1>
      <p>
        When it comes to sourcing high-quality scrap metal for industrial
        purposes, choosing the right scrap metal supplier is crucial. At Avon
        Commercial, we understand the importance of providing top-grade metal
        scrap to meet the demands of various industries, from construction to
        manufacturing. With years of experience in the industry, we have
        established ourselves as a leading metal scrap supplier, known for our
        commitment to quality, reliability, and competitive pricing. Our goal is
        to offer a seamless and efficient supply chain that ensures you receive
        the materials you need when you need them.
      </p>
      <h2>Competitive Steel Scrap Metal Prices for Every Budget</h2>
      <p>
        At Avon Commercial, we believe that quality should never come at an
        exorbitant cost. That's why we offer steel scrap metal prices that are
        not only competitive but also transparent and fair. We understand that
        managing costs is a key concern for businesses, which is why we strive
        to provide pricing that allows you to maintain profitability without
        sacrificing the quality of your materials. Whether you're looking for
        ferrous or non-ferrous scrap, our pricing structure is designed to meet
        the needs of businesses of all sizes, ensuring that you get the best
        value for your investment.
      </p>
      <h2>Wide Range of Scrap Metal Solutions to Meet Your Needs</h2>
      <p>
        As a comprehensive metal scrap supplier, Avon Commercial offers a wide
        range of metal scrap options to cater to various industrial
        applications. Our inventory includes ferrous metals like steel and iron,
        as well as non-ferrous metals such as aluminium, copper, and stainless
        steel. Each type of metal scrap we supply is carefully sourced and
        processed to ensure it meets the highest standards of quality. Whether
        you need bulk quantities for large-scale projects or smaller amounts for
        specialized applications, we have the capacity and resources to fulfill
        your requirements efficiently and effectively.
      </p>
      <h2>Ensuring Consistent Quality and Reliable Supply</h2>
      <p>
        Consistency in quality and reliability in supply are two pillars that
        define our service as a leading scrap metal supplier. We understand that
        the success of your projects depends on the quality of the materials you
        use, which is why we are committed to providing only the best. Our
        rigorous quality control processes ensure that every batch of scrap
        metal we deliver meets industry standards and exceeds your expectations.
        Additionally, our well-established logistics network allows us to
        deliver your orders promptly, minimizing downtime and helping you stay
        on schedule.
      </p>
      <h2>Transparent and Fair Metal Scrap Rates in Pakistan</h2>
      <p>
        Understanding the metal scrap rate in Pakistan is essential for
        businesses looking to make informed purchasing decisions. At Avon
        Commercial, we provide transparent and up-to-date information on metal
        scrap rates, allowing you to plan and budget more effectively. Our
        pricing is influenced by various factors, including market demand,
        material availability, and processing costs, but we always aim to offer
        the most competitive rates possible. By choosing us as your metal scrap
        supplier, you gain access to fair pricing that reflects the true value
        of the materials you purchase, without hidden fees or unexpected
        charges.
      </p>
      <h2>Sustainable and Environmentally Responsible Practices</h2>
      <p>
        In today’s world, sustainability is more than just a buzzword; it's a
        responsibility that we take seriously at Avon Commercial. As a
        conscientious scrap metal supplier, we are committed to environmentally
        responsible practices in all aspects of our operations. We work closely
        with recycling partners to ensure that the scrap metal we supply is
        processed in ways that minimize environmental impact. By choosing us,
        you’re not only getting high-quality materials but also contributing to
        a more sustainable future. Our commitment to green practices makes us
        the preferred choice for businesses that value both quality and
        environmental stewardship.
      </p>
      <h2>Exceptional Customer Service and Support</h2>
      <p>
        At Avon Commercial, we believe that the best products should be matched
        with the best customer service. As your trusted metal scrap supplier, we
        are dedicated to providing exceptional support throughout your
        purchasing experience. From the initial inquiry to the final delivery,
        our team is here to assist you with any questions or concerns you may
        have. We understand that every business has unique needs, and we are
        committed to offering personalized service that helps you get the most
        out of your investment in scrap metal.
      </p>
      <h2>Partner with Avon Commercial for All Your Scrap Metal Needs</h2>
      <p>
        In the competitive world of industrial materials, having a reliable
        partner can make all the difference. Avon Commercial is more than just a
        scrap metal supplier; we are a partner dedicated to helping you succeed.
        With our extensive range of high-quality metal scrap, competitive
        pricing, and unwavering commitment to customer satisfaction, we are here
        to meet all your scrap metal needs. Whether you’re looking for the best
        steel scrap metal prices or need guidance on the metal scrap rate in
        Pakistan, Avon Commercial is your go-to source. Trust us to deliver the
        quality and service you need to keep your projects on track and your
        business thriving.
      </p>
      <h2>Why Choose Avon Commercial?</h2>
      <p>
        Choosing the right metal scrap supplier is essential for ensuring the
        success of your projects. Avon Commercial stands out in the industry for
        several reasons. First, our commitment to quality ensures that you
        receive only the best materials, sourced and processed to meet rigorous
        standards. Second, our competitive pricing helps you manage costs
        without compromising on quality. Third, our reliable supply chain
        ensures that you receive your orders on time, every time. Finally, our
        dedication to customer service means that we are always here to support
        you, from the moment you contact us to the final delivery of your
        materials.
      </p>
      <p>
        At Avon Commercial, we don’t just supply scrap metal; we supply the
        materials that help build your business. Partner with us today and
        experience the difference that quality, reliability, and exceptional
        service can make. Whether you need ferrous or non-ferrous scrap, bulk
        quantities or specialized materials, Avon Commercial is your trusted
        source for all your scrap metal needs in Pakistan.
      </p>`,
      });
      setActivetab(6);
    }
  }, [urlL]);

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>{metadata.title}</title>
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta
          name="description"
          content={metadata.desc}
          data-react-helmet="true"
        />

        {/* open graph */}
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${metadata.title}`} />
        <meta property="og:description" content={`${metadata.desc}`} />
        <meta
          property="og:image"
          content="https://www.avoncommercial.net/assets/service/service.webp"
        />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="byeindonesia.com" />
        <meta
          property="twitter:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta name="twitter:title" content={`${metadata.title}`} />
        <meta name="twitter:description" content={`${metadata.desc}`} />
        <meta
          name="twitter:image"
          content="https://www.avoncommercial.net/assets/service/service.webp"
        />
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${metadata.title}`} />
        <meta property="og:description" content={`${metadata.desc}`} />
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:site_name" content="Avon Commercial" />

        {/* open graph */}
      </Helmet>
      {/* {console.log(`https://www.avoncommercial.net/business-segment/${urlL}`)} */}
      <div className="services">
        <Subbanner
          image={"/assets/service/service.webp"}
          title={metadata.title}
          newTitle={allproducts[activetab]}
          catIndex={subCategory}
        />

        <div className="productwrap">
          {loading ? (
            <div className="divider">
              <div className="catsec">
                {allproducts?.map((item, index) => {
                  return (
                    <div key={index}>
                      <p
                        className={
                          index == activetab ? "activecategory" : "categoryname"
                        }
                        onClick={() => {
                          return setActivetab(index), setSubCategory("hide");
                        }}
                      >
                        {item.category}
                      </p>
                      {/* <div className="subCatWrapper">
                        {item.subcategories?.map((item, index) => {
                          return (
                            <p key={index} className='singleSubCategory'>
                              {item.category}
                            </p>
                          )
                        })}
                        <div>
                        </div>
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div className="productsec">
                {subCategory == "hide" ? (
                  <>
                    {allproducts[activetab]?.products.map((item, index) => {
                      return (
                        <div key={index} className="productbox">
                          <div className="upperwrap">
                            <Link
                              to={`/products/${item.slug}`}
                              onClick={() => {
                                return (
                                  setSingleproduct(item),
                                  setCatimage(imagearr[activetab])
                                );
                              }}
                            >
                              <p className="heading">{item.title}</p>
                            </Link>

                            <p
                              dangerouslySetInnerHTML={createMarkup(
                                item.summary
                              )}
                              className="desc"
                            ></p>
                          </div>
                          <Popupform title={item.title} btnstyle="button" />
                        </div>
                      );
                    })}
                    {allproducts[activetab]?.subcategories.map(
                      (item, index) => {
                        return (
                          <div key={index} className="productbox">
                            <div className="upperwrap">
                              <p className="heading">{item.category}</p>
                              <p className="desc">{item.description}</p>
                            </div>
                            <button
                              className="button"
                              onClick={() => {
                                setSubCategory(index);
                              }}
                            >
                              View Products
                            </button>
                          </div>
                        );
                      }
                    )}
                    {/* print categories */}
                  </>
                ) : (
                  <>
                    {allproducts[activetab]?.subcategories[
                      subCategory
                    ]?.products.map((item, index) => {
                      return (
                        <div key={index} className="productbox">
                          <div className="upperwrap">
                            <Link
                              to={`/products/${item.slug}`}
                              onClick={() => {
                                return (
                                  setSingleproduct(item),
                                  setCatimage(imagearr[activetab])
                                );
                              }}
                            >
                              <p className="heading">{item.title}</p>
                            </Link>

                            <p
                              dangerouslySetInnerHTML={createMarkup(
                                item.summary
                              )}
                              className="desc"
                            ></p>
                          </div>
                          <Popupform title={item.title} btnstyle="button" />
                        </div>
                      );
                    })}
                  </>
                )}

                {/* {console.log(subCategory, '"sadasdsadasdsad')} */}

                {metadata.seoData && (
                  <div
                    className="seoBoxContainer"
                    dangerouslySetInnerHTML={createMarkup(metadata.seoData)}
                  ></div>
                )}
              </div>
            </div>
          ) : (
            <img src={"/assets/service/loadingicon.gif"} />
          )}
        </div>
      </div>
    </>
  );
}

export default Services;
